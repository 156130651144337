import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Image,
  Row,
  Col,
  InputGroup,
  Carousel
} from "react-bootstrap";
import "./UserAuth.css";
import * as Yup from "yup";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import OtpVerification from "./OtpVerification";
import { forgotPasswordStart } from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { ButtonLoader } from "../Helper/Loader";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ForgotPassword = (props) => {

  const t = useTranslation("forgot");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgotPassword = useSelector((state) => state.admin.forgotPassword);
  const verifyForgotPassword = useSelector((state) => state.admin.verifyForgotPassword);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const handleSubmit = (values) => {
    dispatch(forgotPasswordStart(values));
  };

  useEffect(() => {
    if (!skipRender && !forgotPassword.loading && Object.keys(forgotPassword.data).length > 0)
      setShowOTPVerifyModal({
        ...forgotPassword.data,
        type: "email"
      });
    setSkipRender(false);
  }, [forgotPassword]);

  const closeOtpVerifyModal = () => {
    setShowOTPVerifyModal(false);
  };

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
  });

  useEffect(() => {
    if (!skipRender && !verifyForgotPassword.loading && Object.keys(verifyForgotPassword.data).length > 0)
      navigate("/reset-password");
    setSkipRender(false);
  }, [verifyForgotPassword]);

  return (
    <>
      <div className="login-page-sec">
        <div className="login-page-box">
          <Row className=" g-0">
            <Col md={6}>
              <div className="login-left-card">
                <div className="login-auth-top-frame">
                  <LazyLoadImage
                    effect="blur"
                    className="login-auth-logo"
                    src={
                      window.location.origin + "/img/logo-white.png"
                    }
                  />
                </div>
                <div className="login-auth-middle-frame">
                  <LazyLoadImage
                    effect="blur"
                    className="login-auth-avater"
                    src={
                      window.location.origin +
                      "/img/feature/auth/login-auth.png"
                    }
                  />
                  <div className="login-auth-info">
                    <h2>{t("auth_content.heading")}</h2>
                    <p>{t("auth_content.content")}</p>
                  </div>
                </div>
                <div className="login-auth-footer-frame">
                  <Carousel>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#0194ff"
                            class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                          </svg>
                          <span>
                            {" "}
                            {t(
                              "auth_content.continuous_support.heading"
                            )}{" "}
                          </span>
                        </h3>
                        <p>{t("auth_content.continuous_support.content")}</p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#0194ff"
                            class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                          </svg>
                          <span>
                            {" "}
                            {t("auth_content.banking_simplified.heading")}{" "}
                          </span>
                        </h3>
                        <p>{t("auth_content.banking_simplified.content")}</p>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="carousel-content">
                        <h3>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="#0194ff"
                            class="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                          </svg>
                          <span>
                            {" "}
                            {t("auth_content.enhanced_security.heading")}{" "}
                          </span>
                        </h3>
                        <p>{t("auth_content.enhanced_security.content")}</p>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="login-page-form-box">
                <Row className="align-items-center justify-content-center g-0">
                  <Col lg={9} xl={9} md={12}>
                    <div className="efi-bank-auth-form">
                      <div className="efi-bank-auth-title">
                        <h3>{t("heading")}</h3>
                        <p>{t("auth_content.content")}</p>
                      </div>
                      <div className="efi-bank-auth-form-box">
                        <Formik
                          initialValues={{
                            email: "",
                          }}
                          validationSchema={forgotPasswordSchema}
                          onSubmit={handleSubmit}
                        >
                          {({ setFieldValue, values, errors, touched }) => (
                            <FORM>
                              <div className="efi-bank-auth-input-group">
                                <InputGroup className={`${(errors.email && touched.email) ? "input-group-error" : ""}`}>
                                  <InputGroup.Text>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="#c3c7ca"
                                        d="M17 20.5H7c-3 0-5-1.5-5-5v-7c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5z"
                                      ></path>
                                      <path
                                        fill="#171717"
                                        d="M12 12.87c-.84 0-1.69-.26-2.34-.79l-3.13-2.5a.748.748 0 01.93-1.17l3.13 2.5c.76.61 2.05.61 2.81 0l3.13-2.5c.32-.26.8-.21 1.05.12.26.32.21.8-.12 1.05l-3.13 2.5c-.64.53-1.49.79-2.33.79z"
                                      ></path>
                                    </svg>
                                  </InputGroup.Text>
                                  <Field
                                    className="form-control"
                                    type="email"
                                    placeholder="Email"
                                    aria-label="Email"
                                    aria-describedby="email"
                                    name="email"
                                  />
                                </InputGroup>
                                <ErrorMessage
                                  component={"div"}
                                  name="email"
                                  className="errorMsg"
                                />
                              </div>
                              <div className="efi-auth-signin">
                                <Button
                                  className="efi-primary-btn w-100"
                                  type="submit"
                                  disabled={
                                    !showOTPVerifyModal &&
                                    forgotPassword.buttonDisable}
                                >
                                  {!showOTPVerifyModal && forgotPassword.buttonDisable ? 
                                  <ButtonLoader /> :
                                   t("submit_btn.text")}
                                </Button>
                                <p>
                                  {t("have_account")}{" "}
                                  <span className="sign-up">
                                    <Link to="/login">{t("login")}</Link>
                                  </span>
                                </p>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </Col>
                </Row>
                {showOTPVerifyModal ?
                  <OtpVerification
                    showOtpVerify={showOTPVerifyModal}
                    closeOtpVerifyModal={closeOtpVerifyModal}
                    forgotPassword={2}
                  /> : null}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
