import React, { useState, useEffect } from "react";
import {
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import {
  emailVerifyStart,
  forgotPasswordStart,
  verifyForgotPasswordStart,
} from "../../store/slices/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import { ButtonLoader } from "../Helper/Loader";
import { resendOtpStart } from "../../store/slices/AdminSlice";
import { twoStepAuthStart } from "../../store/slices/AdminSlice";
import { useTranslation } from "react-multi-lang";
import { updateEmailStart } from "../../store/slices/AdminSlice";
import { updateEmailCodeStart } from "../../store/slices/AdminSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const OtpVerification = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("verify");
  const emailVerify = useSelector((state) => state.admin.emailVerify);
  const verifyForgotPassword = useSelector((state) => state.admin.verifyForgotPassword);
  const forgotPassword = useSelector((state) => state.admin.forgotPassword);
  const updateEmail = useSelector((state) => state.admin.updateEmail);
  const updateEmailCode = useSelector((state) => state.admin.updateEmailCode);
  const twoStepAuth = useSelector((state) => state.admin.twoStepAuth);
  const resendOtp = useSelector((state) => state.admin.resendOtp);
  const [otp, setOtp] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [expirationTime, setExpirationTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [isExpired, setIsExpired] = useState(false);

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (otp) {
      if (otp.length === 6) {
        if (props.forgotPassword && props.forgotPassword === 2) {
          dispatch(
            verifyForgotPasswordStart({
              email: props.showOtpVerify.email,
              verification_code: otp,
            })
          );
        } else if (props.showOtpVerify.type == "2fa") {
          dispatch(
            twoStepAuthStart({
              verification_code: otp,
              ...props.showOtpVerify,
            })
          );
        } else {
          dispatch(
            emailVerifyStart({
              email: props.showOtpVerify.email,
              remember: props.showOtpVerify.remember,
              verification_code: otp,
            })
          );
        }
      } else {
        getErrorNotificationMessage(t("verification_code_length_error"));
      }
    } else {
      getErrorNotificationMessage(t("verification_code_required_error"));
    }
  };

  const handleresendOtp = () => {
    setOtp("")
    if (props.forgotPassword && props.forgotPassword === 2) {
      dispatch(
        forgotPasswordStart({
          email: props.showOtpVerify.email,
        })
      );
    } else {
      dispatch(
        resendOtpStart({
          email: props.showOtpVerify.email,
          code_type: "email",
        })
      );
    }
  };

  useEffect(() => {
    // if (
    //   !skipRender &&
    //   !emailVerify.loading &&
    //   Object.keys(emailVerify.data).length > 0
    // ) {
    //   if (emailVerify.data.email_status == 1) {
    //     props.closeOtpVerifyModal();
    //     navigate("/");
    //   }
    // }
    if (
      !skipRender &&
      !twoStepAuth.loading &&
      Object.keys(twoStepAuth.data).length > 0
    ) {
      if (twoStepAuth.data.email_status == 1) {
        props.closeOtpVerifyModal();
        navigate("/");
      }
    }
  }, [twoStepAuth]);

  useEffect(() => {
    if (
      !skipRender &&
      !updateEmail.loading &&
      Object.keys(updateEmail.data).length > 0
    ) {
      props.closeOtpVerifyModal();
    }
    setSkipRender(false);
  }, [updateEmail]);

  useEffect(() => {
    const now = new Date().getTime();
    const expireTime = now + 17000;
    setExpirationTime(expireTime);
  }, [resendOtp]);

  function calculateTimeLeft() {
    if (!expirationTime) return 0;
    const currentTime = new Date().getTime();
    const timeLeft = expirationTime - currentTime;
    return timeLeft > 0 ? timeLeft : 0;
  }

  useEffect(() => {
    if (!expirationTime) return;
    const timer =
      // props.showOtpVerify.type !== "2fa" &&
      setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft <= 0) {
          clearInterval(timer);
          setIsExpired(true);
          setTimeLeft(0);
        } else {
          setTimeLeft(newTimeLeft);
        }
      }, 1000);
    return () => clearInterval(timer);
  }, [expirationTime]);

  const formatTime = (milliseconds) => {
    const minutes = Math.floor((milliseconds % (1000 * 3600)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2,"0")}`;
  };

  useEffect(() => {
    if (!skipRender && !resendOtp.loading && Object.keys(resendOtp.data).length > 0) {
      setIsExpired(false);
      const now = new Date().getTime();
      const expireTime = now + 16000;
      setExpirationTime(expireTime);
    }
    setSkipRender(false);
  }, [resendOtp]);

  return (
    <>
      <Modal
        size="md"
        centered
        show={props.showOtpVerify}
        onHide={props.closeOtpVerifyModal}
        className="otp-validation-model"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="sign-up-verification">
            <div className="otp-frame-title">
               <LazyLoadImage
                  effect="blur"
                src={window.location.origin + "/img/feature/otp-icon.svg"}
              />
              <h2>
                {props.showOtpVerify.type == "email"
                  ? t("otp_verification")
                  : t("input_virtual")}
              </h2>
              <p>
                {t("please_input_code")}{" "}
                {props.showOtpVerify.type == "email"
                  ? t("mail_otp")
                  : t("virtual_otp")}{" "}
                {t("for_verification")}
              </p>
            </div>
            <Formik
              initialValues={{
                otp: "",
              }}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FORM className="auth-form-sec">
                  <div className="sign-up-verification-input verification-item">
                    <OtpInput
                      className="otp-box"
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      inputType="tel"
                      shouldAutoFocus
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>
                  <div className="otp-timer">
                  {/* props.showOtpVerify.type !== "2fa" && */}
                    {(!isExpired && props.showOtpVerify.type == "email") ? (
                      <p>{t('time_left')} {formatTime(timeLeft)}</p>
                    ) : null}
                  </div>
                  <div className="efi-bank-default-form">
                    <div className="verification-btn-sec">
                      {props.showOtpVerify.type == "email" ? (
                        <div className="resend-link-sec">
                          {t("if_not_getting_code")},{" "}
                          <Button
                            type="button"
                            className="resend-otp-btn"
                            onClick={handleresendOtp}
                            disabled={
                              emailVerify.buttonDisable ||
                              resendOtp.buttonDisable ||
                              updateEmail.buttonDisable ||
                              updateEmailCode.buttonDisable ||
                              verifyForgotPassword.buttonDisable ||
                              forgotPassword.buttonDisable  || 
                              !isExpired
                            }
                          >
                            {t("resend_otp")}
                          </Button>{" "}
                        </div>
                      ) : null}
                      <div className="form-modal-footer">
                        <Button
                          className="efi-primary-btn w-100"
                          disabled={
                            emailVerify.buttonDisable ||
                            resendOtp.buttonDisable ||
                            updateEmailCode.buttonDisable ||
                            verifyForgotPassword.buttonDisable ||
                            twoStepAuth.buttonDisable ||
                            forgotPassword.buttonDisable || 
                            otp.length != 6
                          }
                          type="submit"
                        >
                          {" "}
                          {emailVerify.buttonDisable ||
                          // resendOtp.buttonDisable ||
                          updateEmailCode.buttonDisable ||
                          verifyForgotPassword.buttonDisable ||
                          twoStepAuth.buttonDisable ? (
                            <ButtonLoader />
                          ) : (
                            t("validate")
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerification;
