import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import {
  businessDocumentsInfoStart,
  businessDocumentsStatusStart,
  updateBusinessDocumentStart,
} from "../../../store/slices/BusinessSlice";
import { getErrorNotificationMessage } from "../../Helper/NotificationMessage";
import * as Yup from "yup";
import { ErrorMessage, Form as FORM, Formik } from "formik";
import { ButtonLoader } from "../../Helper/Loader";
import NoDataFound from "../../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";

const KycManual = (props) => {

  const navigate = useNavigate();
  const businessInfoUpdate = useSelector((state) => state.business.businessInfoUpdate);
  const [certificates, setCertificates] = useState([]);
  const t = useTranslation("kyc_manual");
  const [step, setStep] = useState();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.admin.profile);
  const updateBusinessDocument = useSelector((state) => state.business.updateBusinessDocument);
  const businessDocumentsInfo = useSelector((state) => state.business.businessDocumentsInfo);
  const businessDocumentsStatus = useSelector(state => state.business.businessDocumentsStatus)

  const [files, setFiles] = useState([]);
  const [skipRender, setSkipRender] = useState(true);
  const formRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/pdf": [".pdf"],
        "image/jpg": [".jpg"],
        "image/png": [".png"],
      },
      maxFiles: 1,
      useFsAccessApi: false,
      onDrop: (acceptedFiles, fileRejections) => {
        // if (fileRejections.length > 0) {
        //   const errors = fileRejections[0].errors;
        //   if (errors.some(error => error.code === "file-invalid-type")) {
        //     setErrorMessage("Only PDF files are allowed.");
        //   }
        // } else {
        //   setErrorMessage("");
        // }
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
      // onDragEnter: () => {
      //   setShowMessage(true);
      // },
      // onDragLeave: () => {
      //   setShowMessage(false);
      // },
    });

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required("Please select a file"),
  });

  const handleUpload = () => {
    if (files.length > 0 && files[0].size / 1024 > 4096) {
      getErrorNotificationMessage(
        `Please select file ${
          files.length > 0 ? `with size less than 4 MB` : ""
        }`
      );
      return;
    }
    dispatch(
      updateBusinessDocumentStart({
        document: files[0],
        document_type: certificates[step - 1].value,
      })
    );
  };

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
      dispatch(businessDocumentsInfoStart());
    }
  }, [profile]);

  const removeFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    setFiles([]);
    formRef.current?.setFieldValue("file", null);
  }, [step]);

  useEffect(() => {
    if (
      !skipRender &&
      !businessDocumentsInfo.buttonDisable &&
      Object.keys(businessDocumentsInfo.data).length > 0
    ) {
      setCertificates(businessDocumentsInfo.data.documents_list.map((item, index) => ({
        id: index + 1,
        label: item.label,
        value: item.documentType,
        supported: item.documentFileAccepted, 
      })));
      setStep(businessDocumentsInfo.data.kyb_documents.length + 1);
    }
    setSkipRender(false);
  }, [businessDocumentsInfo]);

  return (
    <>
      <div className="onboarding-kyc-manual-sec onboarding-auth">
        <div className="onboarding-auth-header onboarding-kyc-manual-header-sec resp-mrg-btm-xs">
          <div className="onboarding-auth-titles">
            <h3>{t("heading")}</h3>
            <p>
              {t("content")}
            </p>
          </div>
          <Button
            className="efi-overlay-btn"
            onClick={() => navigate("/")}
            disabled={businessInfoUpdate.buttonDisable}
          >
            {t("skip_onboarding")}
          </Button>
        </div>
        <div className="onboarding-kyc-manual-body-sec">
          {businessDocumentsInfo.loading ? (
            <Row>
              <Col md={5}>
                <Skeleton height={400} width={"100%"} />
              </Col>
              <Col md={5}>
                <Skeleton height={400} width={"100%"} />
              </Col>
            </Row>
          ) : Object.keys(businessDocumentsInfo.data).length > 0 ? (
            <Row>
              {step <= (certificates.length) ? (
                <React.Fragment>
                  <Col md={6} className="resp-marg-btm-xl">
                    <div className="onboarding-kyc-manual-upload-list-sec mb-3">
                      <ul className="list-unstyled onboarding-kyc-manual-upload-list">
                        {certificates.map((item, key) => (
                          <li
                            className={`${
                              step == item.id
                                ? "current-state"
                                : step > item.id
                                ? "completed"
                                : ""
                            }`}
                            key={key}
                          >
                            <div className="onboarding-kyc-manual-upload-list-title">
                              {item.label}
                            </div>
                            <div className="complete-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="#40AC78"
                                class="icon icon-tabler icons-tabler-filled icon-tabler-circle-check"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                              </svg>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Formik
                      initialValues={{
                        file: null,
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleUpload}
                      innerRef={formRef}
                    >
                      {({ setFieldValue, values, touched, errors }) => (
                        <div className="onboarding-kyc-manual-upload-box">
                          <div className="onboarding-kyc-manual-upload-header-sec">
                            {step ? (
                              <h4>
                                {/* Certificate of  */}
                                {certificates[step - 1].label}
                              </h4>
                            ) : null}
                          </div>
                          <FORM noValidate className="onboarding-form">
                            <div className="onboarding-kyc-manual-upload-body-sec">
                              <div className="onboarding-kyc-manual-upload-item">
                                {files.length <= 0 ? (
                                  <React.Fragment>
                                    <div className="onboarding-kyc-manual-upload-card">
                                      <div
                                        {...getRootProps({
                                          onDrop: (e) => {
                                            // setShowMessage(false);
                                            let ext =
                                              e.dataTransfer.files[0].name.slice(
                                                e.dataTransfer.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              );
                                            if (
                                              e.dataTransfer.files.length ==
                                                1 &&
                                              getInputProps().accept.includes(
                                                ext
                                              ) &&
                                              e.dataTransfer.files[0].type
                                            )
                                              setFieldValue(
                                                "file",
                                                e.dataTransfer.files
                                              );
                                            else if (
                                              !getInputProps().accept.includes(
                                                ext
                                              )
                                            )
                                              getErrorNotificationMessage(
                                                t("file.invalid")
                                              );
                                            else
                                              getErrorNotificationMessage(
                                                t("please_upload_para")
                                              );
                                          },
                                          onChange: (e) => {
                                            let ext =
                                              e.target.files[0].name.slice(
                                                e.target.files[0].name.lastIndexOf(
                                                  "."
                                                )
                                              );
                                            if (
                                              e.target.files.length == 1 &&
                                              getInputProps().accept.includes(
                                                ext
                                              )
                                            )
                                              setFieldValue(
                                                "file",
                                                e.target.files
                                              );
                                            else if (
                                              !getInputProps().accept.includes(
                                                ext
                                              )
                                            )
                                              getErrorNotificationMessage(
                                                t("file.invalid")
                                              );
                                            else
                                              getErrorNotificationMessage(
                                                t("please_upload_para")
                                              );
                                          },
                                        })}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="onboarding-kyc-manual-upload-dropzone-card">
                                          <div className="onboarding-kyc-manual-upload-dropzone-icon-sec">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="35"
                                              height="35"
                                              fill="none"
                                              viewBox="0 0 35 35"
                                            >
                                              <path
                                                fill="#171717"
                                                d="M21.058 10.334c-.277 0-.554-.1-.773-.312l-2.96-2.876-2.96 2.876c-.423.411-1.123.411-1.546 0a1.047 1.047 0 010-1.502l3.733-3.626a1.123 1.123 0 011.546 0L21.83 8.52c.423.411.423 1.091 0 1.502a1.098 1.098 0 01-.773.312z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M17.325 21.214c-.598 0-1.094-.482-1.094-1.063V5.744c0-.58.496-1.063 1.094-1.063.598 0 1.094.482 1.094 1.063V20.15c0 .595-.496 1.063-1.094 1.063z"
                                              ></path>
                                              <path
                                                fill="#171717"
                                                d="M17.5 29.459c-7.51 0-12.76-5.1-12.76-12.396 0-.58.495-1.063 1.093-1.063s1.094.482 1.094 1.063c0 6.05 4.346 10.27 10.573 10.27s10.573-4.22 10.573-10.27c0-.58.496-1.063 1.094-1.063.598 0 1.093.482 1.093 1.063 0 7.296-5.25 12.396-12.76 12.396z"
                                              ></path>
                                            </svg>
                                          </div>
                                          <div className="onboarding-kyc-manual-upload-dropzone-info-sec">
                                            <h4>
                                              {t("choose_doc")}
                                            </h4>
                                            <p>{t("files_supported")} : {certificates[step - 1].supported.map((item => (
                                              item + " "
                                            )))}</p>
                                          </div>
                                          <div className="onboarding-kyc-manual-upload-dropzone-upload-btn-sec">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="none"
                                              viewBox="0 0 16 17"
                                            >
                                              <path
                                                fill="#55ADFF"
                                                d="M13.645 13.74c-1.548 1.549-3.43 2.323-5.645 2.323-2.215 0-4.108-.774-5.677-2.323C.774 12.17 0 10.278 0 8.063c0-2.215.774-4.097 2.323-5.645C3.893.848 5.785.063 8 .063c2.215 0 4.097.785 5.645 2.355C15.215 3.966 16 5.848 16 8.063c0 2.215-.785 4.107-2.355 5.677zM12.548 3.515C11.301 2.245 9.785 1.61 8 1.61c-1.785 0-3.312.635-4.58 1.904-1.248 1.247-1.872 2.763-1.872 4.548 0 1.785.624 3.312 1.871 4.58C4.69 13.892 6.215 14.516 8 14.516c1.785 0 3.301-.624 4.548-1.871 1.27-1.27 1.904-2.796 1.904-4.581 0-1.785-.635-3.301-1.904-4.548zm-3.903 8.677h-1.29c-.258 0-.387-.129-.387-.387V8.063H4.806c-.172 0-.29-.075-.354-.226-.065-.172-.043-.312.064-.42l3.226-3.225c.172-.172.344-.172.516 0l3.226 3.226c.107.107.129.247.064.42-.064.15-.182.225-.354.225H9.032v3.742c0 .258-.129.387-.387.387z"
                                              ></path>
                                            </svg>
                                            {t("choose_file")}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <ErrorMessage
                                      component={"div"}
                                      name="file"
                                      className="errorMsg"
                                    />
                                    {/* {errorMessage && <p className="errorMsg" style={{ color: "red" }}>{errorMessage}</p>} */}
                                  </React.Fragment>
                                ) : (
                                  files &&
                                  files.map((file, index) => (
                                    <div className="onboarding-kyc-manual-upload-card mb-3">
                                      <div className="onboarding-kyc-manual-upload-dropzone-card">
                                        <div className="onboarding-kyc-manual-upload-dropzone-icon-sec">
                                          <svg
                                            width="35"
                                            height="35"
                                            viewBox="0 0 31 31"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="0.886047"
                                              y="0.417542"
                                              width="29.2279"
                                              height="29.2279"
                                              rx="14.614"
                                              fill="#ECFEE1"
                                            />
                                            <rect
                                              x="0.886047"
                                              y="0.417542"
                                              width="29.2279"
                                              height="29.2279"
                                              rx="14.614"
                                              stroke="#09AB70"
                                              stroke-width="0.835083"
                                            />
                                            <path
                                              fill-rule="evenodd"
                                              clip-rule="evenodd"
                                              d="M10.049 14.4573C10.3822 14.1241 10.7989 14.0006 10.9798 14.1814L14.4924 17.6941C14.6732 17.8749 14.5497 18.2916 14.2165 18.6248C13.8833 18.958 13.4666 19.0816 13.2857 18.9007L9.77311 15.3881C9.59228 15.2073 9.7158 14.7905 10.049 14.4573Z"
                                              fill="#50B512"
                                              stroke="#50B511"
                                              stroke-width="0.835083"
                                              stroke-linecap="round"
                                            />
                                            <path
                                              d="M13.2224 17.2281C12.9038 17.5467 12.9038 18.0632 13.2224 18.3817C13.541 18.7003 14.0575 18.7003 14.376 18.3817L20.762 11.9958C21.0805 11.6773 21.0805 11.1608 20.762 10.8422C20.4434 10.5236 19.9269 10.5236 19.6084 10.8422L13.2224 17.2281Z"
                                              fill="#50B512"
                                              stroke="#50B511"
                                              stroke-width="0.835083"
                                              stroke-linecap="round"
                                            />
                                          </svg>
                                        </div>
                                        <div className="onboarding-kyc-manual-upload-dropzone-info-sec">
                                          <h4>{t("select_doc")}</h4>
                                          {/* <p>File Supported png, jpeg & pdf</p> */}
                                        </div>
                                        <div className="onboarding-kyc-manual-uploaded-dropzone-upload-btn-sec">
                                          <div className="onboarding-kyc-manual-uploaded-dropzone-upload-left">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="icon icon-tabler icons-tabler-outline icon-tabler-paperclip"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5" />
                                            </svg>
                                          </div>
                                          <div className="onboarding-kyc-manual-uploaded-dropzone-upload-middle">
                                            {file.name}{" "}
                                            {file.size >= 1024
                                              ? (
                                                  file.size /
                                                  (1024 * 1024)
                                                ).toFixed(2) + " MB"
                                              : (file.size / 1024).toFixed(2) +
                                                " KB"}
                                          </div>
                                          <div
                                            className="onboarding-kyc-manual-uploaded-dropzone-upload-right"
                                            onClick={() => {
                                              removeFile(index);
                                              setFieldValue("file", null);
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="#FF5E5E"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="icon icon-tabler icons-tabler-outline icon-tabler-x"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              />
                                              <path d="M18 6l-12 12" />
                                              <path d="M6 6l12 12" />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                            <div className="onboarding-auth-footer">
                              <div className="space-tow">
                                <Button
                                  className="efi-overlay-btn"
                                  type="button"
                                  onClick={() => navigate("/settings")}
                                >
                                  <span>{t("edit_onboarding")}</span>
                                </Button>
                              </div>
                              <div className="space-tow">
                                <Button
                                  className="efi-primary-btn"
                                  type="submit"
                                  disabled={
                                    updateBusinessDocument.buttonDisable
                                  }
                                >
                                  {updateBusinessDocument.buttonDisable ? (
                                    <ButtonLoader />
                                  ) : (
                                    t("upload")
                                  )}
                                </Button>
                              </div>
                            </div>
                          </FORM>
                        </div>
                      )}
                    </Formik>
                  </Col>
                </React.Fragment>
              ) : (
                <div className="onboarding-final-frame">
                  <div className="onboarding-inner-area">
                    <div className="welcome-onboarding-img-sec">
                      <Image
                        src={window.location.origin + "/img/kyb.svg"}
                        className="illustration-avater mb-3"
                      />
                    </div>
                    <div className="welcome-onboarding-info-sec">
                      <h4>{t("verification_progress")}</h4>
                    </div>
                    <div className="onboarding-auth-footer mt-3">
                      <div className="space-tow"></div>
                      <div className="space-tow">
                        <Button
                          className="efi-overlay-btn"
                          type="button"
                          onClick={() => navigate("/settings")}
                        >
                          <span>{t("edit_onboarding")}</span>
                        </Button>
                      </div>
                      <div className="space-tow">
                        <Button
                          className="efi-overlay-btn"
                          type="button"
                          disabled={businessDocumentsStatus.buttonDisable}
                          onClick={() => dispatch(businessDocumentsStatusStart())}
                        >
                          <span>{businessDocumentsStatus.buttonDisable ? <ButtonLoader/> : t("check_kyb")}</span>
                        </Button>
                      </div>
                      <div className="space-tow"></div>
                    </div>
                  </div>
                </div>
              )}
            </Row>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </>
  );
};
export default KycManual;
