import React, { useEffect, useState } from "react";
import { toggleSidebar } from "../../../store/slices/CommonSlice";
import {
  Navbar,
  Container,
  Image,
  Button,
  Dropdown,
  Nav,
  Alert
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import RadialSeparators from "./RadialSeparators";
import { useDispatch, useSelector } from "react-redux";
import useLogout from "../../hooks/useLogout";
import CardSendMoneyModal from "../../Card/CardSendMoneyModal";
import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../../Helper/NotificationMessage";
import OtpVerification from "../../Card/OtpVerification";
import { setLanguage, useTranslation } from "react-multi-lang";
import LogoutConfirmationModal from "../../Helper/LogoutConfirmationModal";
import { Tooltip } from 'react-tooltip'
import ReactLanguageSelect from "react-languages-select";

const MainHeader = (props) => {

  const { logout } = useLogout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("header");

  const sidebar = useSelector(state => state.common.sidebar);
  const profile = useSelector((state) => state.admin.profile);
  const transferMoney = useSelector((state) => state.payment.transferMoney);

  const [percentage, setPercentage] = useState();
  const [moneyModal, setMoneyModal] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const closeOtpVerifyModal = () => {
    setShowOTPVerifyModal(false);
  };

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding == 1) {
        setPercentage(0);
      } else if (profile.data.onboarding == 2) {
        setPercentage(33);
      }
      else if (profile.data.onboarding == 3) {
        setPercentage(66);
      } else {
        setPercentage(100);
      }
    }
  }, [profile]);

  // useEffect(() => {
  //   if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
  //     setPercentage(((profile.data.onboarding / 4) * 100).toFixed(0));
  //   }
  // }, [profile]);

  useEffect(() => {
    if (
      !skipRender &&
      !transferMoney.loading &&
      Object.keys(transferMoney.data).length > 0
    ) {
      setShowOTPVerifyModal(false);
    }
    setSkipRender(false);
  }, [transferMoney]);

  return (
    <>
      <header>
        <div className={`admin-ui-header-sec ${sidebar ? "sidebar-header" : ""
          }`}>
          <Navbar expand="lg">
            <Container fluid>
              <div className="admmin-left-frame">
                <div className="admin-ui-title-sec">
                  <div
                    className="admin-ui-collapse-btn"
                    onClick={() => dispatch(toggleSidebar())}
                  >
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="#0194ff"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 3.5c0 .83-.67 1.5-1.5 1.5h-21C.67 5 0 4.33 0 3.5S.67 2 1.5 2h21c.83 0 1.5.67 1.5 1.5zM6.5 20h-5c-.83 0-1.5.67-1.5 1.5S.67 23 1.5 23h5c.83 0 1.5-.67 1.5-1.5S7.33 20 6.5 20zm8-9h-13c-.83 0-1.5.67-1.5 1.5S.67 14 1.5 14h13c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z"></path>
                    </svg> */}
                    {
                      !sidebar ?
                      <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#171717"
                        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81v8.37C2 19.83 4.17 22 7.81 22h8.37c3.64 0 5.81-2.17 5.81-5.81V7.81C22 4.17 19.83 2 16.19 2z"
                        opacity="0.4"
                      ></path>
                      <path
                        fill="#171717"
                        d="M17 8.25H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10c.41 0 .75.34.75.75s-.34.75-.75.75zM17 12.75H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10c.41 0 .75.34.75.75s-.34.75-.75.75zM17 17.25H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10c.41 0 .75.34.75.75s-.34.75-.75.75z"
                      ></path>
                    </svg>
                        :<svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          fill="#000"
                          data-name="Layer 1"
                          viewBox="0 0 24 24"
                        >
                          <path d="M23.341 9.48l-3.501-6A5.016 5.016 0 0015.522 1H8.451a5.02 5.02 0 00-4.319 2.48l-3.501 6a5.01 5.01 0 000 5.039l3.501 6a5.016 5.016 0 004.318 2.48h7.071a5.02 5.02 0 004.319-2.48l3.5-6a5.01 5.01 0 000-5.039zm-1.729 4.031l-3.499 6a3.011 3.011 0 01-2.592 1.488H8.45a3.01 3.01 0 01-2.591-1.488l-3.5-6a3.004 3.004 0 010-3.023l3.499-6A3.011 3.011 0 018.45 3h7.071a3.01 3.01 0 012.591 1.488l3.5 6a3.004 3.004 0 010 3.023zm-5.905-3.805l-2.293 2.293 2.293 2.293a.999.999 0 11-1.414 1.414L12 13.413l-2.293 2.293a.997.997 0 01-1.414 0 .999.999 0 010-1.414l2.293-2.293-2.293-2.293a.999.999 0 111.414-1.414L12 10.585l2.293-2.293a.999.999 0 111.414 1.414z"></path>
                        </svg>
                    
                       

                    }


                  </div>
                  <h2>EFIcyent</h2>
                </div>
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#0194ff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-user"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                  <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#171717"
                    d="M12 2C9.38 2 7.25 4.13 7.25 6.75c0 2.57 2.01 4.65 4.63 4.74.08-.01.16-.01.22 0h.07a4.738 4.738 0 004.58-4.74C16.75 4.13 14.62 2 12 2z"
                    opacity="0.4"
                  ></path>
                  <path
                    fill="#171717"
                    d="M17.08 14.15c-2.79-1.86-7.34-1.86-10.15 0-1.27.85-1.97 2-1.97 3.23s.7 2.37 1.96 3.21C8.32 21.53 10.16 22 12 22c1.84 0 3.68-.47 5.08-1.41 1.26-.85 1.96-1.99 1.96-3.23-.01-1.23-.7-2.37-1.96-3.21z"
                  ></path>
                </svg>
              </Navbar.Toggle>
              <div className="efi-bank-header-actions">
                <Navbar.Collapse id="basic-navbar-nav">
                 {profile.data.rfi_note.length > 0 ? <div className="alerts-warning-box">
                    <a
                      className="my-anchor-element"
                      data-tooltip-variant="error"
                    >
                      {['danger'].map((variant) => (
                        <Alert key={variant} variant={variant}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="currentColor"  class="icon icon-tabler icons-tabler-filled icon-tabler-alert-triangle"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm.01 13.33l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -7a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" /></svg>
                          Important Update 
                        </Alert>
                      ))}
                    </a>
                    <Tooltip anchorSelect=".my-anchor-element" place="top" className="tooltip-element-box">
                      {profile.data.rfi_note}
                    </Tooltip>
                  </div> : null}
                  <div className="efi-action-head">
                    {/* <Button className="efi-primary-btn" onClick={() => profile.data.onboarding != 4 ? getErrorNotificationMessage("Please complete onboarding first!") : configuration.get("configData.stylopay_service_status") == 1 ? setMoneyModal(true) : getErrorNotificationMessage("Service not available, Please try again later!")}>
                    {t('send_money')}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <g fill="#fff">
                        <path
                          d="M7 18c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l10-10c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-10 10c-.2.2-.4.3-.7.3z"
                          data-original="#000000"
                        ></path>
                        <path
                          d="M17 17c-.6 0-1-.4-1-1V8H8c-.6 0-1-.4-1-1s.4-1 1-1h9c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1z"
                          data-original="#000000"
                        ></path>
                      </g>
                    </svg>
                  </Button> */}
                    {profile.data.onboarding != 4 ? (
                      <div className="efi-onboarding-process-card">
                        <div className="efi-onboarding-process-card-bar">
                          <CircularProgressbarWithChildren
                            value={percentage}
                            text={`${percentage}%`}
                            strokeWidth={14}
                            strokeColor="#0194ff"
                            trackStrokeColor="#FDFAF2"
                            styles={buildStyles({
                              strokeLinecap: "butt",
                            })}
                          >
                            <RadialSeparators
                              count={3}
                              style={{
                                background: "#fff",
                                width: "2px",
                                height: `${14}%`,
                              }}
                            />
                          </CircularProgressbarWithChildren>
                        </div>
                        {profile.data.onboarding != 4 ?
                          <div className="efi-onboarding-process-card-info">
                            {/* <h3>{t('onboarding')}</h3> */}
                            {profile.data.onboarding > 1 ? <Link className="efi-overlay-btn onboarding" to="/user-onboarding">
                              {" "}
                              <span> {t('continue')} </span>
                            </Link> : null}
                          </div>
                          :
                          null
                        }
                      </div>
                    ) : null}
                  </div>
                  <Nav className="ms-auto" style={{display:"flex", alignContent:"center", gap:'1em', flexWrap:"unset"}}>
                    <div className="payment-link-dropdown">
                      <Dropdown>
                        <div className="payment-link-icon-sec">
                          <Dropdown.Toggle id="dropdown-basic">
                            <CustomLazyLoad
                              className="user-dropdown-img"
                              src={profile.data.picture || (window.location.origin + "/img/user-img.svg")}
                              type="image/png"
                            />
                            <div className="profile-drop-info">
                              <p>
                                {profile.data.first_name}{" "}
                                {profile.data.last_name}
                              </p>
                              <span>{profile.data.email}</span>
                            </div>
                          </Dropdown.Toggle>
                        </div>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => navigate("/profile")}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#656565"
                                d="M12 22.01c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
                                opacity="0.4"
                              ></path>
                              <path
                                fill="#656565"
                                d="M12 6.94c-2.07 0-3.75 1.68-3.75 3.75 0 2.03 1.59 3.68 3.7 3.74h.18a3.743 3.743 0 003.62-3.74c0-2.07-1.68-3.75-3.75-3.75zM18.78 19.36A9.976 9.976 0 0112 22.01c-2.62 0-5-1.01-6.78-2.65.24-.91.89-1.74 1.84-2.38 2.73-1.82 7.17-1.82 9.88 0 .96.64 1.6 1.47 1.84 2.38z"
                              ></path>
                            </svg>
                            <span> {t('profile')}</span>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setLogoutModal(true)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M9 7.2v9.59C9 20 11 22 14.2 22h2.59c3.2 0 5.2-2 5.2-5.2V7.2C22 4 20 2 16.8 2h-2.6C11 2 9 4 9 7.2z"
                                opacity="0.4"
                              ></path>
                              <path
                                fill="#171717"
                                d="M12.43 8.12l3.35 3.35c.29.29.29.77 0 1.06l-3.35 3.35c-.29.29-.77.29-1.06 0a.754.754 0 010-1.06l2.07-2.07H2.75c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10.69l-2.07-2.07a.742.742 0 01-.22-.53c0-.19.07-.38.22-.53.29-.3.76-.3 1.06 0z"
                              ></path>
                            </svg>
                            <span> {t('logout')}</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <ReactLanguageSelect 
                      defaultLanguage={localStorage.getItem("lang")} 
                      languages={["en", "es"]} 
                      onSelect={(option) => {
                        localStorage.setItem("lang", option);
                        setLanguage(option);
                        // window.location.reload();
                      }}
                    />
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Container>
          </Navbar>
        </div>
        {moneyModal ? <CardSendMoneyModal
          show={moneyModal}
          setShowOTPVerifyModal={setShowOTPVerifyModal}
          onHide={() => setMoneyModal(false)}
        /> : null}
        {showOTPVerifyModal ? (
          <OtpVerification
            showOtpVerify={showOTPVerifyModal}
            closeOtpVerifyModal={closeOtpVerifyModal}
          />
        ) : null}
        {logoutModal ? (
          <LogoutConfirmationModal
            show={logoutModal}
            onHide={() => setLogoutModal(false)}
          />
        ) : null}
      </header>
    </>
  );
};

export default MainHeader;
