import { React, useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, Image } from "react-bootstrap";
import "./modal.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import useLogout from "../hooks/useLogout";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordStart } from "../../store/slices/AdminSlice";
import { ButtonLoader } from "../Helper/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CustomLazyLoad from "../Helper/CustomLazyLoad";

const ChangePasswordModal = (props) => {

  const { logout } = useLogout();
  const dispatch = useDispatch();
  const t = useTranslation("modals.change_password");

  const changePassword = useSelector((state) => state.admin.changePassword);

  const [showPassword, setShowPassword] = useState({
    old_password: true,
    password: true,
    confirmPassword: true,
  });
  const [skipRender, setSkipRender] = useState(true);
  
  const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .required(t("old_password.required"))
      .min(6, t("required_note"))
      .matches(/^\S*$/, t("old_password.no_spaces")),
    password: Yup.string()
      .required(t("new_password.required"))
      .matches(/^\S*$/, t("new_password.no_spaces"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided
          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(6, t("required_note")),
    password_confirmation: Yup.string()
      .required(t("confirm_password.required"))
      .matches(/^\S*$/, t("confirm_password.no_spaces"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided
          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(6, t("required_note"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.invalid")
        ),
      }),
  });

  const togglePasswordVisibility = (value) => {
    if (value == 1)
      setShowPassword((prevState) => ({
        ...prevState,
        old_password: !prevState.old_password,
      }));
    else if (value == 2)
      setShowPassword((prevState) => ({
        ...prevState,
        password: !prevState.password,
      }));
    else
      setShowPassword((prevState) => ({
        ...prevState,
        confirmPassword: !prevState.confirmPassword,
      }));
  };

  const handlePasswordChange = (values) => {
    dispatch(changePasswordStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !changePassword.loading &&
      Object.keys(changePassword.data).length > 0
    ) {
      logout();
    }
    setSkipRender(false);
  }, [changePassword]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="change-password-modal"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="change-password-form-heading mb-3">
          <CustomLazyLoad
            effect="blur"
            className="modal-popup-icon"
            src={window.location.origin + "/img/change-password-icon.svg"}
            type="image/png"
          />
        </div>
        <h3 className="change-password-heading mb-4">{t("heading")}</h3>
        <div className="efi-bank-auth-form-box">
          <Formik
            initialValues={{
              old_password: "",
              password: "",
              password_confirmation: "",
            }}
            validationSchema={changePasswordSchema}
            onSubmit={handlePasswordChange}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <FORM className="efi-bank-default-form">
                <div className="mb-3">
                  <InputGroup>
                    {/* <InputGroup.Text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#dde1e6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-key"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z" />
                        <path d="M15 9h.01" />
                      </svg>
                    </InputGroup.Text> */}
                    <Field
                      className="form-control"
                      name="old_password"
                      type={!showPassword.old_password ? "text" : "password"}
                      placeholder={t("old_password.placeholder")}
                      aria-label="Password"
                      aria-describedby="Password-addon"
                      maxLength={16}
                    />
                    <InputGroup.Text
                      className="password-icon"
                      onClick={() => togglePasswordVisibility(1)}
                    >
                      {!showPassword.old_password ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#c3c7ca"
                            d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                          ></path>
                          <path
                            fill="#171717"
                            d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#c3c7ca"
                            d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                          ></path>
                          <path
                            fill="#171717"
                            d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                          ></path>
                          <path
                            fill="#c3c7ca"
                            d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                          ></path>
                          <path
                            fill="#171717"
                            d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                          ></path>
                        </svg>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="old_password"
                    className="errorMsg"
                  />
                </div>
                <div className="mb-3">
                  <InputGroup>
                    {/* <InputGroup.Text>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#dde1e6"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-key"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z" />
                        <path d="M15 9h.01" />
                      </svg>
                    </InputGroup.Text> */}
                    <Field
                      className="form-control"
                      name="password"
                      type={!showPassword.password ? "text" : "password"}
                      placeholder={t("new_password.placeholder")}
                      aria-label="Password"
                      aria-describedby="Password-addon"
                      maxLength={16}
                    />
                    <InputGroup.Text
                      className="password-icon"
                      onClick={() => togglePasswordVisibility(2)}
                    >
                      {!showPassword.password ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#c3c7ca"
                            d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                          ></path>
                          <path
                            fill="#171717"
                            d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#c3c7ca"
                            d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                          ></path>
                          <path
                            fill="#171717"
                            d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                          ></path>
                          <path
                            fill="#c3c7ca"
                            d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                          ></path>
                          <path
                            fill="#171717"
                            d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                          ></path>
                        </svg>
                      )}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component={"div"}
                    name="password"
                    className="errorMsg"
                  />
                </div>
                <InputGroup>
                  {/* <InputGroup.Text>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#dde1e6"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-key"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M16.555 3.843l3.602 3.602a2.877 2.877 0 0 1 0 4.069l-2.643 2.643a2.877 2.877 0 0 1 -4.069 0l-.301 -.301l-6.558 6.558a2 2 0 0 1 -1.239 .578l-.175 .008h-1.172a1 1 0 0 1 -.993 -.883l-.007 -.117v-1.172a2 2 0 0 1 .467 -1.284l.119 -.13l.414 -.414h2v-2h2v-2l2.144 -2.144l-.301 -.301a2.877 2.877 0 0 1 0 -4.069l2.643 -2.643a2.877 2.877 0 0 1 4.069 0z" />
                      <path d="M15 9h.01" />
                    </svg>
                  </InputGroup.Text> */}
                  <Field
                    className="form-control"
                    name="password_confirmation"
                    type={!showPassword.confirmPassword ? "text" : "password"}
                    placeholder={t("confirm_password.placeholder")}
                    aria-label="Password"
                    aria-describedby="Password-addon"
                    maxLength={16}
                  />
                  <InputGroup.Text
                    className="password-icon"
                    onClick={() => togglePasswordVisibility(3)}
                  >
                    {!showPassword.confirmPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#c3c7ca"
                          d="M21.25 9.15C18.94 5.52 15.56 3.43 12 3.43c-1.78 0-3.51.52-5.09 1.49-1.58.98-3 2.41-4.16 4.23-1 1.57-1 4.12 0 5.69 2.31 3.64 5.69 5.72 9.25 5.72 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69zM12 16.04c-2.24 0-4.04-1.81-4.04-4.04S9.76 7.96 12 7.96s4.04 1.81 4.04 4.04-1.8 4.04-4.04 4.04z"
                        ></path>
                        <path
                          fill="#171717"
                          d="M12 9.14a2.855 2.855 0 000 5.71c1.57 0 2.86-1.28 2.86-2.85S13.57 9.14 12 9.14z"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#c3c7ca"
                          d="M21.25 9.15a14.57 14.57 0 00-1.63-2.11l-3.77 3.77c.12.37.19.77.19 1.19 0 2.24-1.81 4.04-4.04 4.04-.42 0-.82-.07-1.19-.19l-3.46 3.46c1.46.82 3.04 1.25 4.65 1.25 1.78 0 3.51-.52 5.09-1.49 1.58-.98 3-2.41 4.16-4.23 1-1.56 1-4.12 0-5.69z"
                        ></path>
                        <path
                          fill="#171717"
                          d="M14.02 9.98l-4.04 4.04c-.51-.52-.84-1.24-.84-2.02 0-1.57 1.28-2.86 2.86-2.86.78 0 1.5.33 2.02.84z"
                        ></path>
                        <path
                          fill="#c3c7ca"
                          d="M18.25 5.75l-3.39 3.39A3.986 3.986 0 0012 7.96c-2.24 0-4.04 1.81-4.04 4.04 0 1.12.45 2.13 1.18 2.86l-3.38 3.39h-.01c-1.11-.9-2.13-2.05-3-3.41-1-1.57-1-4.12 0-5.69C3.91 7.33 5.33 5.9 6.91 4.92c1.58-.96 3.31-1.49 5.09-1.49 2.23 0 4.39.82 6.25 2.32z"
                        ></path>
                        <path
                          fill="#171717"
                          d="M14.86 12c0 1.57-1.28 2.86-2.86 2.86-.06 0-.11 0-.17-.02l3.01-3.01c.02.06.02.11.02.17zM21.77 2.23c-.3-.3-.79-.3-1.09 0L2.23 20.69c-.3.3-.3.79 0 1.09a.758.758 0 001.08-.01L21.77 3.31c.31-.3.31-.78 0-1.08z"
                        ></path>
                      </svg>
                    )}
                  </InputGroup.Text>
                </InputGroup>
                <ErrorMessage
                  component={"div"}
                  name="password_confirmation"
                  className="errorMsg"
                />
                <div className="efi-auth-signin">
                  <Button
                    className="efi-primary-btn w-100"
                    type="submit"
                    disabled={changePassword.buttonDisable}
                  >
                    {" "}
                    {changePassword.buttonDisable ? (
                      <ButtonLoader varient="black" />
                    ) : (
                      t("confirm")
                    )}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
